import { Routes } from '@angular/router';
import { ComponentConstants } from './@core/constants/component.constants';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';

export const AppRoutes: Routes = [
    // {
    //     path: 'dashboard',
    //     redirectTo: 'dashboard',
    //     pathMatch: 'full'
    // },
    // {
    //     path: '',
    //     redirectTo: 'oktaLogin',
    //     pathMatch: 'full'
    // },
    {
        path: '404notFound',
        component: NotFoundComponent
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: ComponentConstants.DASHBOARD_PATH,
                loadChildren: './NetworkDashboard/networkDashboard.module#NetworkDashboardModule'
            },
            // {
            //     path: 'components',
            //     loadChildren: './components/components.module#ComponentsModule'
            // },
            // {
            //     path: 'forms',
            //     loadChildren: './forms/forms.module#Forms'
            // },
            // {
            //     path: 'tables',
            //     loadChildren: './tables/tables.module#TablesModule'
            // },
            // {
            //     path: 'maps',
            //     loadChildren: './maps/maps.module#MapsModule'
            // },
            // {
            //     path: 'widgets',
            //     loadChildren: './widgets/widgets.module#WidgetsModule'
            // },
            // {
            //     path: 'charts',
            //     loadChildren: './charts/charts.module#ChartsModule'
            // },
            // {
            //     path: 'calendar',
            //     loadChildren: './calendar/calendar.module#CalendarModule'
            // },
            // {
            //     path: '',
            //     loadChildren: './userpage/user.module#UserModule'
            // },
            // {
            //     path: '',
            //     loadChildren: './timeline/timeline.module#TimelineModule'
            // }
        ]
    },
    // {
    //     path: '',
    //     component: AuthLayoutComponent,
    //     children: [
    //         {
    //             path: 'pages',
    //             loadChildren: './pages/pages.module#PagesModule'
    //         }
    //     ]
    // }
];
