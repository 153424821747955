export class ComponentConstants {
  // Bucket Names
  static readonly DEV_BUCKET_NAME = 'networkinventory-dev';
  static readonly UAT_BUCKET_NAME = 'networkinventory-prod';
  static readonly PROD_BUCKET_NAME = 'networkinventory-prod';
  // Navbar Component
  static readonly TITLE_DASHBOARD = 'IT Infrastructure Inventory Dashboard';
  static readonly DASHBOARD_PATH = 'networkDashboard';

  // Admin Component Constants
  static readonly CHECKSUM_FILE_NAME = 'checksum.json';
  static readonly CHECKSUM_FILE_TYPE = 'application/json';
  static readonly JAR_FILE_EXTENSION = '.jar';
  static readonly TOOLKIT_FILE_NAME = 'toolkit';
  static readonly SUCCESSFULL_UPLOAD_MSG = 'A new row is added.';
  static readonly SUCCESSFULL_UPDATE_MSG = 'The row is updated.';
  // S3 BUCKET FILE AND FOLDER DETAILS
  static readonly FOLDER_NAME = 'INVENTORY-FOLDER';
  static readonly FILE_NAME = 'network-inventory-file.json';
  static readonly READ_ACTION_NAME = 'checksum';
  static readonly UPLOAD_ACTION_NAME = 'upload';
  // JSON FILE DETAILS
  static readonly JSON_FILE_TYPE = 'json';
  static readonly JSON_FILE_NAME = 'network-inventory-file';
  static readonly ACTIVE = 'ACTIVE';
  static readonly INACTIVE = 'INACTIVE';
}
