import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { ComponentConstants } from '../constants/component.constants';
import { AppService } from './app.service';

@Injectable()
export class NavbarService {

    public year$: Subject<any> = new Subject<any>();
    public view$: BehaviorSubject<any> = new BehaviorSubject<any>('Qtr To Date');
    public orderType$: BehaviorSubject<any> = new BehaviorSubject<any>('All');

    public notificationData;
    public notificationData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public appService: AppService, public http: HttpClient) { }
}
