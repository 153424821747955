import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject } from 'rxjs';
import { AppUtil } from '.././util/app.util';
import { AppConstant } from '../constants/app.constants';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public usCurrentDate;
    public usCurrentDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(public http: HttpClient) {
     }
    public APIkey;
    public APIkey$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    getHTTPClient() {
        return this.http;
    }
    async checkGetMethod(url: string) {
        const data = await this.http.get<{ data: Object }>(url,
            { observe: 'response', headers: AppUtil.getHttpHeaders()} ).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    async checkGetMethodWithParams(url: string, param: HttpParams) {
        const data = await this.http.get<{ data: Object }>(url,
            { observe: 'response', params: param, headers: AppUtil.getHttpHeaders()}).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    async checkPatchMethodWithParams(url: string, param: HttpParams, body) {
        const data = await this.http.patch<{ data: Object }>(url, body, { observe: 'response',
        params: param, headers: AppUtil.getHttpHeaders() }).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    async checkPostMethod(url: string, body) {
        return await this.http.post(url, body, { observe: 'response' }).toPromise() as any;
    }
    async checkPostMethodWithParams(url: string, param, body) {
        const data = await this.http.post<{ data: Object }>(url, body, { observe: 'response',
        params: param, headers: AppUtil.getHttpHeaders() }).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    // without headers
    async checkPostMethodWithoutheaders(url: string, param, body) {
        const data = await this.http.post<{ data: Object }>(url, body, { observe: 'response',
        params: param}).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    async checkPutMethodWithParams(url: string, param, body) {
        const data = await this.http.put<{ data: Object }>(url, body, { observe: 'response',
        params: param, headers: AppUtil.getHttpHeaders() }).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    checkError(status: number) {
        if (status === 202) {
            alert('The request has been accepted for processing, but the processing has not been completed');
        }
        if (status === 203) {
            alert('Partial Information');
        }
        if (status === 204) {
            alert('Server has received the request but there is no information to send back');
        }
        if (status === 400) {
            alert('bad request');
        }
        if (status === 401) {
            alert('UNAUTHIRIZED: The client should retry the request with a suitable Authorization header.');
        }
        if (status === 402) {
            alert('PaymentRequired: The client may retry the request with a suitable ChargeTo header.');
        }
        if (status === 403) {
            alert('Forbidden: The request is for something forbidden. Authorization will not help.');
        }
        if (status === 404) {
            alert('Not Found: The server has not found anything matching the URI given');
        }
        if (status === 500) {
            alert('server not found');
        }
        if (status === 501) {
            alert('Not implemented: The server does not support the facility required.');
        }
        if (status === 502) {
            alert('Service temporarily overloaded');
        }
        if (status === 503) {
            alert('Gateway timeout');
        }
    }
    async getAPIkey() {
        // let param: HttpParams = AppUtil.getHttpParams();
        // param = param.set('filter', AppConstant.USER_DATA_API_URL_FILTER.replace('<<replaceUserName>>', userName));https://secretsmanager.us-east-1.amazonaws.com/
        const method = 'POST';
        const host = 'secretsmanager.us-east-1.amazonaws.com';
        const service = 'secretsmanager';
        const region = 'us-east-1';
        const signed_headers = 'content-type;host;x-amz-date;x-amz-target';
        const request_parameters = 'Action=DescribeRegions&Version=2013-10-15';
        const endPoint = AppConstant.API_KEY_URL;
        const datestamp = AppConstant.currentDate.replaceAll('/', '');
        const d = AppConstant.currentTimeStamp.replaceAll('/', '').replaceAll(':', '');
        const amzdate  = d.split(' ')[0] + 'T' + d.split(' ')[1] + 'Z';
        const access_key = 'AKIAUPDVBLG2STSK7IFC';
        const secret_key = 'GjvanDV6dyQWhJJPinlxWtWzIXODs+5Fd8DGSzWo';
        const algorithm = 'AWS4-HMAC-SHA256';
        const signing_key = AppUtil.getSignatureKey(secret_key, datestamp, region, service);
        const payload_hash = CryptoJS.SHA256(request_parameters).toString(CryptoJS.enc.Base64);
        const canonical_uri = '/';
        const canonical_querystring = '';
        const canonical_headers = 'host:' + host + '\n' + 'x-amz-date:' + amzdate + '\n';
        const canonical_request = method + '\n' + canonical_uri + '\n' + canonical_querystring + '\n' + canonical_headers + '\n' + signed_headers + '\n' + payload_hash;
        const credential_scope = datestamp + '/' + region + '/' + service + '/' + 'aws4_request';
        const string_to_sign = algorithm + '\n' +  amzdate + '\n' +  credential_scope + '\n' +  CryptoJS.SHA256(canonical_request).toString(CryptoJS.enc.Base64);;
        const signature = CryptoJS.HmacSHA256(string_to_sign, signing_key).toString(CryptoJS.enc.Base64);;
        // const signature = Base64.stringify(HmacSHA256(signing_key, string_to_sign);
        const authorization_header = algorithm + ' ' + 'Credential=' + access_key + '/' + credential_scope + ', ' +  'SignedHeaders=' + signed_headers + ', ' + 'Signature=' + signature;
        // const authorization_header = 'AccessKey: ' + access_key + ',' + 'SecretKey: ' + secret_key + ',' + 'AWS Region: ' + region + ',' +'Service Name: ' + 'secretsmanager';
        const body =
        {
            "SecretId": "arn:aws:secretsmanager:us-east-1:307335616949:secret:test_dev_secret-kweeDA",
            "VersionStage": "AWSCURRENT"
        };
        let header: HttpHeaders = new HttpHeaders();
        header = header.set('Content-Length', '<calculated when request is sent>');
        header = header.set('Host', '<calculated when request is sent>');
        // header = header.set('User-Agent', '<calculated when request is sent>');
        header = header.set('X-Amz-Target', 'secretsmanager.GetSecretValue');
        header = header.set('Content-Type', 'application/x-amz-json-1.1');
        header = header.set('x-amz-date', amzdate);
        header = header.set('Authorization', authorization_header);
        const data =  this.http.post<{ data: Object }>(AppConstant.API_KEY_URL, body, { observe: 'response',
        headers: header}).toPromise() as any;
        this.setAPIkey(data);
    }
    setAPIkey(data: any) {
        this.APIkey = data;
        this.APIkey$.next(this.APIkey);
    }
    onAPIkey() {
        return this.APIkey$.asObservable();
    }
    // getUserData(userName): Observable<any> {
    //     let param: HttpParams = AppUtil.getHttpParams();
    //     param = param.set('filter', AppConstant.USER_DATA_API_URL_FILTER.replace('<<replaceUserName>>', userName));
    //     return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.LOGIN_TABLENAME), { params: param,
    //     headers: AppUtil.getHttpHeaders()});
    // }
    // setUserData(data: any) {
    //     this.loginData = data;
    //     this.loginData$.next(this.loginData);
    // }
    // onUserData() {
    //     return this.loginData$.asObservable();
    // }
    // Post the current user in the login table
    // insertCurrentUser(userEmailId) {
    //     const updateColumns = {
    //         resource: [
    //             {
    //                 EMAIL_ID: userEmailId,
    //                 FIRST_NAME: userEmailId.split('@')[0].slice(0, 1),
    //                 LAST_NAME: userEmailId.split('@')[0].slice(1,(userEmailId.split('@')[0]).length),
    //                 PROFILE_PIC: '/assets/img/profilePics/bowboy.png',
    //                 STATUS: 'ACTIVE'
    //             }
    //         ]
    //     };
    //     let param: HttpParams = AppUtil.getHttpParams();
    //     return this.http.post<{ data: Object }>(AppUtil
    //         .getAppUrl(AppConstant.LOGIN_TABLENAME), updateColumns, { observe: 'response',
    //         params: param, headers: AppUtil.getHttpHeaders() });
    // }
}
