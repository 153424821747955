import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { ComponentConstants } from 'src/app/@core/constants/component.constants';
import { NavbarModal } from 'src/app/@core/modal/navBar.modal';
import { AppUtil } from 'src/app/@core/util/app.util';
import { ROUTES } from '../.././sidebar/sidebar.component';


const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    navBarModalData: NavbarModal = new NavbarModal();
    @Input() isShow = false;
    @Output() updateValue = new EventEmitter();
    public listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    public nativeElement: Node;
    public toggleButton: any;
    public sidebarVisible: boolean;
    public _router: Subscription;
    public current_url: string;
    selectedItems = [];
    @ViewChild('app-navbar-cmp') button: any;
    innerWidth: number;
    constructor(
        location: Location,
        public element: ElementRef,
        public router: Router
    ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }
    getUsername () {
        if (AppUtil.isNullOrUndefined(AppConstant.USER_EMAIL_ID)) {
            return '';
        }
        this.navBarModalData.firstName = AppConstant.USER_EMAIL_ID.split('@')[0].slice(0, 1);
        this.navBarModalData.lastName = AppConstant.USER_EMAIL_ID.split('@')[0].slice(1,(AppConstant.USER_EMAIL_ID.split('@')[0]).length);
        return this.navBarModalData.firstName + ' ' + this.navBarModalData.lastName;
    }
    onItemSelect(item: any) {
        this.selectedItems.push(item);
    }
    onSelectAll(items: any) {
        console.log(items);
    }
    // minimizeSidebar() {
    //     const body = document.getElementsByTagName('body')[0];

    //     if (misc.sidebar_mini_active === true) {
    //         body.classList.remove('sidebar-mini');
    //         misc.sidebar_mini_active = false;
    //     } else {
    //         setTimeout(function() {
    //             body.classList.add('sidebar-mini');

    //             misc.sidebar_mini_active = true;
    //         }, 300);
    //     }

    //     // we simulate the window Resize so the charts will get updated in realtime.
    //     const simulateWindowResize = setInterval(function() {
    //         window.dispatchEvent(new Event('resize'));
    //     }, 180);

    //     // we stop the simulation of Window Resize after the animations are completed
    //     setTimeout(function() {
    //         clearInterval(simulateWindowResize);
    //     }, 1000);
    // }
    // hideSidebar() {
    //     const body = document.getElementsByTagName('body')[0];
    //     const sidebar = document.getElementsByClassName('sidebar')[0];

    //     if (misc.hide_sidebar_active === true) {
    //         setTimeout(function() {
    //             body.classList.remove('hide-sidebar');
    //             misc.hide_sidebar_active = false;
    //         }, 300);
    //         setTimeout(function() {
    //             sidebar.classList.remove('animation');
    //         }, 600);
    //         sidebar.classList.add('animation');
    //     } else {
    //         setTimeout(function() {
    //             body.classList.add('hide-sidebar');
    //             // $('.sidebar').addClass('animation');
    //             misc.hide_sidebar_active = true;
    //         }, 300);
    //     }

    //     // we simulate the window Resize so the charts will get updated in realtime.
    //     const simulateWindowResize = setInterval(function() {
    //         window.dispatchEvent(new Event('resize'));
    //     }, 180);

    //     // we stop the simulation of Window Resize after the animations are completed
    //     setTimeout(function() {
    //         clearInterval(simulateWindowResize);
    //     }, 1000);
    // }

    ngOnInit() {
        
        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        // if (body.classList.contains('sidebar-mini')) {
        //     misc.sidebar_mini_active = true;
        // }
        // if (body.classList.contains('hide-sidebar')) {
        //     misc.hide_sidebar_active = true;
        // }
        // this._router = this.router.events
        //     .filter(event => event instanceof NavigationEnd)
        //     .subscribe((event: NavigationEnd) => {
        //         this.sidebarClose();

        //         const $layer = document.getElementsByClassName('close-layer')[0];
        //         if ($layer) {
        //             $layer.remove();
        //         }
        //     });
    }
    onResize(event) {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    // sidebarOpen() {
    //     const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    //     const toggleButton = this.toggleButton;
    //     const body = document.getElementsByTagName('body')[0];
    //     setTimeout(function() {
    //         toggleButton.classList.add('toggled');
    //     }, 500);
    //     body.classList.add('nav-open');
    //     setTimeout(function() {
    //         $toggle.classList.add('toggled');
    //     }, 430);

    //     const $layer = document.createElement('div');
    //     $layer.setAttribute('class', 'close-layer');

    //     if (body.querySelectorAll('.main-panel')) {
    //         document.getElementsByClassName('main-panel')[0].appendChild($layer);
    //     } else if (body.classList.contains('off-canvas-sidebar')) {
    //         document
    //             .getElementsByClassName('wrapper-full-page')[0]
    //             .appendChild($layer);
    //     }

    //     setTimeout(function() {
    //         $layer.classList.add('visible');
    //     }, 100);

    //     $layer.onclick = function() {
    //         // asign a function
    //         body.classList.remove('nav-open');
    //         this.mobile_menu_visible = 0;
    //         this.sidebarVisible = false;

    //         $layer.classList.remove('visible');
    //         setTimeout(function() {
    //             $layer.remove();
    //             $toggle.classList.remove('toggled');
    //         }, 400);
    //     }.bind(this);

    //     body.classList.add('nav-open');
    //     this.mobile_menu_visible = 1;
    //     this.sidebarVisible = true;
    // }
    // sidebarClose() {
    //     const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    //     const body = document.getElementsByTagName('body')[0];
    //     this.toggleButton.classList.remove('toggled');
    //     const $layer = document.createElement('div');
    //     $layer.setAttribute('class', 'close-layer');

    //     this.sidebarVisible = false;
    //     body.classList.remove('nav-open');
    //     // $('html').removeClass('nav-open');
    //     body.classList.remove('nav-open');
    //     if ($layer) {
    //         $layer.remove();
    //     }

    //     setTimeout(function() {
    //         $toggle.classList.remove('toggled');
    //     }, 400);

    //     this.mobile_menu_visible = 0;
    // }
    // sidebarToggle() {
    //     if (this.sidebarVisible === false) {
    //         this.sidebarOpen();
    //     } else {
    //         this.sidebarClose();
    //     }
    // }
    getTitle() {
        let titlee: string = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.includes('#') ? titlee.split('#')[1] : titlee;
        if (titlee.includes('/' + ComponentConstants.DASHBOARD_PATH)) {
            return ComponentConstants.TITLE_DASHBOARD;
        }
        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }
}
