import { PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AppUtil } from '../@core/util/app.util';
import { AppService } from './../@core/data/app.service';
import { NavbarService } from './../@core/data/navbar.service';
declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    roleViewId?: number;
    roleEditId?: number;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/' + ComponentConstants.TIMEOFF_DASHBOARD_PATH,
    //     title: 'ADMIN',
    //     type: 'sub',
    //     icontype: 'library_books',
    //     collapse: 'ADMIN',
    //     children: [
    //         { path: ComponentConstants.VERSION_DASHBOARD_PATH, title: 'Version Details Report', ab: 'VDR' }
    //     ]
    // }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public currentUserData: any;
    currentUserFirstName: any;
    currentUserLastName: any;
    public fName: string;
    EMAIL_ID: string;
    OL_ADMIN: number;
    roleList: any;
    userRoleList: any;
    userAdminValue: any;
    userRoleArray: any = [];
    cost: boolean;
    current_url: string;
    view = 'View';
    viewOrder = 'OrderType';
    viewTest = 'TestType';
    yearView = 'Year View';
    viewList = ['Qtr To Date', 'Previous Qtr', 'Month To Date', 'Complete View'];
    viewOrderList = ['All', ' Distributor', 'Federal', 'Regular', 'Internal'];
    viewTestList = ['All', ' Licconf', 'SWDL', 'CTO'];
    yearList = ['FY19', 'FY18', 'FY17', 'FY16', 'FY15', 'FY14', 'FY13', 'FY12', 'Complete View'];
    innerWidth: number;
    imgUrl: any;
    constructor(
        public appService: AppService,
        public cd: ChangeDetectorRef,
        public ngZone: NgZone,
        location: PlatformLocation,
        public navbarService: NavbarService
    ) {
        this.userRoleArray = [];
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.getCurrentUserEmail();
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>(
                document.querySelector('.sidebar .sidebar-wrapper')
            );
            const ps = new PerfectScrollbar(elemSidebar, {
                wheelSpeed: 2,
                suppressScrollX: true
            });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (
            navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
            navigator.platform.toUpperCase().indexOf('IPAD') >= 0
        ) {
            bool = true;
        }
        return bool;
    }
    isBackButton() { }
    logout() {
        localStorage.clear();
        return true;
    }
    checkUserViewPermission(permissionId: number) {
        if (AppUtil.isNullOrUndefined(permissionId)) {
            return true;
        }
        return (this.userAdminValue !== 0 || this.userRoleArray.includes(permissionId));
    }

    checkTestBloxUser() {
        if (AppUtil.isNullOrUndefined(this.userRoleArray)) {
            return false;
        }
        return this.userRoleArray.length === 1 && this.userRoleArray.includes(8192);
    }
    checkUserEditPermission(permissionId: number) {
        if (AppUtil.isNullOrUndefined(permissionId)) {
            return true;
        }
        return (this.userAdminValue === 1 || this.userRoleArray.includes(permissionId));
    }
    getCurrentUserEmail () {
        this.imgUrl = '/assets/img/profilePics/boy.png';
        // this.currentUserFirstName = SessionProperties.USER_EMAIL_ID.split('@')[0].slice(0, 1);
        // this.currentUserLastName = SessionProperties.USER_EMAIL_ID.split('@')[0].slice(1,(SessionProperties.USER_EMAIL_ID.split('@')[0]).length);
    }
}
