import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs/Subscription';
import { AppUtil } from 'src/app/@core/util/app.util';
import { AppConstant } from './@core/constants/app.constants';
import { ComponentConstants } from './@core/constants/component.constants';
import { AppService } from './@core/data/app.service';
import { OktaUtils } from './@core/util/okta.util';
@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    public _router: Subscription;
    public isAuthenticated = false;
    public userData: any;
    public dateData = false;
    public userDataJson: any = {};
    constructor(public router: Router, public appService: AppService) {
        switch (AppConstant.ENVIROMENT) {
            case 'PROD' : this.validateLogin(); break;
            case 'UAT' :  this.validateLogin(); break;
            case 'DEV': this.executeinLocal();break;
            case 'LOCAL': this.executeinLocal();
        }
    }
    executeinLocal () {
        this.isAuthenticated = true;
        AppConstant.USER_EMAIL_ID = 'svahinipati@infoblox.com';
        this.router.navigate([ComponentConstants.DASHBOARD_PATH]);
        this.setLocalstorage();
        this.getUSCurrentDate();
    }
    async setLocalstorage () {
        localStorage.setItem('Lastname', 'Vahinipati');
        localStorage.setItem('Firstname', 'Shilpa');
        localStorage.setItem('Email', 'svahinipati@infoblox.com');
    }
    getDecodedJWTtoken(token) {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
    isLogFileWindow() {
        return window.location.href.includes('testblox/showLogFileData');
    }
    isLocalStorageVarriablesSet() {
        return (!AppUtil.isNullOrUndefined(localStorage.getItem('Lastname'))
            && !AppUtil.isNullOrUndefined(localStorage.getItem('Firstname'))
            && !AppUtil.isNullOrUndefined(localStorage.getItem('Email')));
    }

    async validateLogin() {
        const windowLocation = window.location.href;
        if (!windowLocation.includes('jwt=')) {
            OktaUtils.redirectForAuthorization();
        } else {
            AppConstant.JWT = window.location.href.split('jwt=')[1];
            try {
                const jwtUserID = this.getDecodedJWTtoken(AppConstant.JWT).sub;
                AppConstant.USER_EMAIL_ID = jwtUserID;
                this.router.navigate([ComponentConstants.DASHBOARD_PATH]);
            } catch (e) {
                OktaUtils.redirectForAuthorization();
            }
            this.isAuthenticated = true;
        }
        this.getUSCurrentDate();
    }

    logout() {
        localStorage.clear();
    }
    getUSCurrentDate() {
        const today = new Date();
        AppConstant.currentTimeStamp = new DatePipe('en-Us').transform(today, 'yyyy/MM/dd hh:mm:ss', 'UTC');
        AppConstant.currentDate = AppConstant.currentTimeStamp.split(' ')[0];
        AppConstant.currentYear = AppConstant.currentDate.split('/')[0];
        this.dateData = true;
    }
    subscribeToData () {
        // GET API KEY
        this.appService.onAPIkey().subscribe(param => {
            if (!AppUtil.isNullOrUndefined(param)) {
            } else {
                this.appService.getAPIkey();
            }
        })
    }
    ngOnInit() {
        // this.subscribeToData();
        this._router = this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe((event: NavigationEnd) => {
                const body = document.getElementsByTagName('body')[0];
                const modalBackdrop = document.getElementsByClassName(
                    'modal-backdrop'
                )[0];
                if (body.classList.contains('modal-open')) {
                    body.classList.remove('modal-open');
                    modalBackdrop.remove();
                }
            });
    }
}
