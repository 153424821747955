import { Component } from '@angular/core';
import { AppConstant } from 'src/app/@core/constants/app.constants';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    test: Date = new Date();
    footerPrvYear = (parseInt(AppConstant.currentYear, 10) - 1);
    footerCurYear = AppConstant.currentYear;
    constructor() { }
}
