import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { ComponentConstants } from '../constants/component.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class NetworkDashboardService {
    public formData;
    public formData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public inventoryData;
    public inventoryData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(private appService: AppService, public http: HttpClient) { }
    async getInventoryData(action: string) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('bucket', AppUtil.getBucketName());
        params = params.set('file_name', ComponentConstants.FILE_NAME);
        params = params.set('folder', ComponentConstants.FOLDER_NAME);
        params = params.set('action', ComponentConstants.READ_ACTION_NAME);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAPIUrl(), params);
        this.setInventoryData(data.body);
    }
    setInventoryData(data: any) {
        this.inventoryData = data;
        this.inventoryData$.next(this.inventoryData);
    }
    onInventoryData() {
        return this.inventoryData$.asObservable();
    }
    getFormData(folderName: string, fileName: string, action: string):Observable<any> {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('bucket', AppUtil.getBucketName());
        params = params.set('folder', folderName);
        params = params.set('file_name', fileName);
        params = params.set('action', action);
        return this.http.get(AppUtil.getAPIUrl(), { observe: 'response', headers: AppUtil.getHttpHeaders(), params: params });
    }
    setFormData(data: any) {
        this.formData = data;
        this.formData$.next(this.formData);
    }
    onFormData() {
        return this.formData$.asObservable();
    }
    uploadBlanaceFiles(formdata: any, fileName):Observable<any>  {
        // this.setDataLoader(true);
        let url = formdata.url;
        let formData: FormData = new FormData(); 
        formData.append('key', formdata.fields.key); 
        formData.append('AWSAccessKeyId', formdata.fields.AWSAccessKeyId); 
        formData.append('x-amz-security-token', formdata.fields['x-amz-security-token']); 
        formData.append('policy', formdata.fields.policy); 
        formData.append('signature', formdata.fields.signature);
        formData.append('file', fileName);
        return this.http.post(url, formData);
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
