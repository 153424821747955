import { DatePipe } from '@angular/common';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { Workbook } from 'exceljs/dist/exceljs';
import * as fs from 'file-saver';
import swal from 'sweetalert2';
import { AppConstant } from '.././constants/app.constants';
import { ComponentConstants } from '../constants/component.constants';

export class AppUtil {
    constructor() { }
    static getSignatureKey(key, dateStamp, regionName, serviceName) {
        var kDate = CryptoJS.HmacSHA256(dateStamp, "AWS4" + key);
        var kRegion = CryptoJS.HmacSHA256(regionName, kDate);
        var kService = CryptoJS.HmacSHA256(serviceName, kRegion);
        var kSigning = CryptoJS.HmacSHA256("aws4_request", kService).toString(CryptoJS.enc.Base64);
        return kSigning;
    }
    static isNullOrUndefined(value) {
        return (value === null || value === undefined);
    }
    static getAPIUrl(): string {
        let url = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': url = AppConstant.PROD_API_URL; break;
            case 'DEV': url = AppConstant.DEV_API_URL; break;
            case 'UAT': url = AppConstant.UAT_API_URL; break;
            case 'LOCAL': url = AppConstant.DEV_API_URL; break;
        }
        return url;
    }

    static getAPIKey(): string {
        let apiKey = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': apiKey = AppConstant.PROD_API_KEY; break;
            case 'DEV': apiKey = AppConstant.DEV_API_KEY; break;
            case 'UAT': apiKey = AppConstant.UAT_API_KEY; break;
            case 'LOCAL': apiKey = AppConstant.DEV_API_KEY; break;
        }
        return apiKey;
    }
    
    static isEmptyArray(array: any) {
        return AppUtil.isNullOrUndefined(array) || array.length === 0;
    }
    static getBucketName () {
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': return ComponentConstants.PROD_BUCKET_NAME;
            case 'UAT': return ComponentConstants.UAT_BUCKET_NAME;
            case 'DEV': return ComponentConstants.DEV_BUCKET_NAME;
            case 'LOCAL': return ComponentConstants.DEV_BUCKET_NAME;
        }
    }
    static convertToJson(jsonString: string) {
        if (AppUtil.isNullOrUndefined(jsonString) || typeof jsonString !== 'string') {
            return jsonString;
        }
        try {
            return JSON.parse(jsonString);
        } catch (e) {
            return jsonString;
        }
    }

    static getHttpParams(): HttpParams {
        let param: HttpParams = new HttpParams();
        return param;
    }
    // HEADERS
    static getHttpHeaders(): HttpHeaders {
        let header: HttpHeaders = new HttpHeaders();
        header = header.set(AppConstant.HEADER_API_KEY, AppUtil.getAPIKey());
        return header;
    }

    static showSwal(title: string, fontsize: number, message: string) {
        swal({
            title: title + '\r\n' + message.fontsize(fontsize),
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success'
        }).catch(swal.noop);
    }
    // Methods for Generating excel

    static geneRateExcelFromFile(dataFile: any[], title: string, header: string[], seperator: string, extension: string) {
        const data = [];
        data.push([]);
        dataFile.forEach(item => {
            data.push(item.split(seperator));
        });
        this.downloadExcel(data, title, header, extension);
    }
    // Generate Excel from JSON format data
    static generateExcelFromJSON(title: string, header: string[], dataArray: any[], extension: string) {
        const data = [];
        if (AppUtil.isNullOrUndefined(dataArray)) {
            return null;
        }
        dataArray.forEach(item => {
            const rowData = [];
            header.forEach(head => {
                rowData.push(item[head]);
            });
            data.push(rowData);
        });
        this.downloadExcel(data, title, header, extension);
    }
    static convertJson(jsonArray: any[], seperator: string, title: string, extension: string) {
        if (AppUtil.isNullOrUndefined(jsonArray)) {
            return null;
        }
        const rowArray = [];
        const index = [];
        jsonArray.forEach((item) => {
            Object.keys(item).forEach(key => {
                if (index.indexOf(key) === -1) {
                    index.push(key);
                }
            });
        });
        index.splice(index.indexOf('CREATED_ON'));
        jsonArray.forEach((item) => {
            let row = '';
            index.forEach(key => {
                row += AppUtil.isNullOrUndefined(item[key]) ? '' : item[key];
                row += seperator;
            });
            row = row.slice(0, row.length - 1);
            rowArray.push(row + '\r\n');
        });
        this.downloadFile(rowArray, title, extension);
    }
    static downloadExcel(data: any[], title: string, header: string[], extension: string) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(title);
        const headerRow = worksheet.addRow(header);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'd9d9d9' },
                bgColor: { argb: 'd9d9d9' }
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });
        data.forEach(d => {
            worksheet.addRow(d);
        });
        worksheet.views = [
            {
                state: 'frozen',
                xSplit: 0,
                ySplit: 1,
                topLeftCell: 'G10',
                activeCell: '1'
            }
        ];
        worksheet.autoFilter = {
            from: 'A1',
            to: 'BB1',
        };
        workbook.xlsx.writeBuffer().then(sdata => {
            const blob = new Blob([sdata as BlobPart], {
                // type: AppConstant.EXCEL_TYPE
            });
            fs.saveAs(blob, title + extension);
        });
    }
    static downloadFile(data: any, title: string, extension: string) {
        const blob = new Blob(data, { type: extension });
        fs.saveAs(blob, title + extension);
    }
    // Method to check if Valid Hexadecimal or not
    static is_hexadecimal(str) {
    const regexp = /^[0-9a-fA-F]{32}$/;
        if (regexp.test(str)) {
            return true;
        }
        else {
            return false;
        }
    }
    static sqlDateFormat(anyDate, anyformat) {
        const datePipe = new DatePipe('en-US');
        try {
            return datePipe.transform(anyDate, anyformat);
        } catch (e) {
            return null;
        }
    }
}
