export class NavbarModal {
    public totalRecords: any;
    public firstName: string;
    public lastName: string;
    constructor() {
        this.totalRecords = 0;
    }
    public setTotalRecords(totalRecords: number) {
            this.totalRecords = totalRecords;
    }

    public getTotalRecords() {
        return this.totalRecords;
    }
}
