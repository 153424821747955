import { Location, PopStateEvent } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Subscription } from 'rxjs/Subscription';
import { NavbarService } from 'src/app/@core/data/navbar.service';
import { NavItem, NavItemType } from '../../md/md.module';
import { NavbarComponent } from '../../shared/navbar/navbar.component';

declare const $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AdminLayoutComponent implements OnInit, AfterViewInit {
    public navItems: NavItem[];
    public _router: Subscription;
    public lastPoppedUrl: string;
    public yScrollStack: number[] = [];
    url: string;
    location: Location;
    userDataService: any;
    tabLabel = 'Home';
    userData: any = [];
    lookUpdata: any = [];
    tabAccess = {
      isFullTime: false,
      isAdmin: false,
      isStaff: false,
      isTabDataLoaded: false
    }
    @ViewChild('sidebar') sidebar: any;
    @ViewChild(NavbarComponent) navbar: NavbarComponent;
    constructor( public router: Router, location: Location, public navbarService: NavbarService ) {
      this.location = location;
    }
    // subscribeToData () {
    //   // DWPROD data of the current user
    //   this.userDataService = this.navbarService.onUserData().subscribe(params => {
    //       if (!AppUtil.isNullOrUndefined(params)) {
    //         this.userData = params[0];
    //         if (this.isFullTimeEmployee()) {
    //             this.tabAccess.isFullTime = true;
    //             this.navbarService.getLookUpData();
    //         } else {
    //           this.tabAccess.isTabDataLoaded = true;
    //         }
    //       }
    //   });
    //   this.userDataService = this.navbarService.onLookUpData().subscribe(params => {
    //     if (!AppUtil.isNullOrUndefined(params)) {
    //       this.lookUpdata = params[0].lookup_desc;
    //       this.tabAccess.isAdmin = this.isAdmin(); 
    //       if (!this.tabAccess.isAdmin) {
    //         this.tabAccess.isStaff = this.isStaff();
    //       }
    //       this.tabAccess.isTabDataLoaded = true;
    //     }
    //   });
    // }
    ngOnInit() {
      // this.subscribeToData();
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
         this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
               if (event.url !== this.lastPoppedUrl) {
                   this.yScrollStack.push(window.scrollY);
               }
           } else if (event instanceof NavigationEnd) {
               if (event.url === this.lastPoppedUrl) {
                   this.lastPoppedUrl = undefined;
                   window.scrollTo(0, this.yScrollStack.pop());
               } else {
                   window.scrollTo(0, 0);
               }
           }
        });
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            //  elemMainPanel.scrollTop = 0;
            //  elemSidebar.scrollTop = 0;
        });
        const html = document.getElementsByTagName('html')[0];
        // if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
        //     let ps = new PerfectScrollbar(elemMainPanel);
        //     ps = new PerfectScrollbar(elemSidebar);
        //     html.classList.add('perfect-scrollbar-on');
        // } else {
        //     html.classList.add('perfect-scrollbar-off');
        // }
        // this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        //   this.navbar.sidebarClose();
        // });

        this.navItems = [
          { type: NavItemType.NavbarLeft, title: 'Dashboard', iconClass: 'fa fa-dashboard' },

          {
            type: NavItemType.NavbarRight,
            title: '',
            iconClass: 'fa fa-bell-o',
            numNotifications: 5,
            dropdownItems: [
              { title: 'Notification 1' },
              { title: 'Notification 2' },
              { title: 'Notification 3' },
              { title: 'Notification 4' },
              { title: 'Another Notification' }
            ]
          },
          {
            type: NavItemType.NavbarRight,
            title: '',
            iconClass: 'fa fa-list',

            dropdownItems: [
              { iconClass: 'pe-7s-mail', title: 'Messages' },
              { iconClass: 'pe-7s-help1', title: 'Help Center' },
              { iconClass: 'pe-7s-tools', title: 'Settings' },
               'separator',
              { iconClass: 'pe-7s-lock', title: 'Lock Screen' },
              { iconClass: 'pe-7s-close-circle', title: 'Log Out' }
            ]
          },
          { type: NavItemType.NavbarLeft, title: 'Search', iconClass: 'fa fa-search' },

          { type: NavItemType.NavbarLeft, title: 'Account' },
          {
            type: NavItemType.NavbarLeft,
            title: 'Dropdown',
            dropdownItems: [
              { title: 'Action' },
              { title: 'Another action' },
              { title: 'Something' },
              { title: 'Another action' },
              { title: 'Something' },
              'separator',
              { title: 'Separated link' },
            ]
          },
          { type: NavItemType.NavbarLeft, title: 'Log out' }
        ];
    }
    ngAfterViewInit() {
        this.runOnRouteChange();
    }
    public isMap() {
        if (this.location.prepareExternalUrl(this.location.path()) === '/maps/fullscreen') {
            return true;
        } else {
            return false;
        }
    }
    runOnRouteChange(): void {
      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
        const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        // let ps = new PerfectScrollbar(elemMainPanel);
        // ps = new PerfectScrollbar(elemSidebar);
        // ps.update();
      }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
