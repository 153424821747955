import { Md5 } from 'ts-md5/dist/md5';
export class AppConstant {
    // HEADER FOR SESSION TOKEN
    static HEADER_SESSION_TOKEN = 'X-DreamFactory-Session-Token';
    // SESSION TOKEN
    static JWT = '';
    static USER_EMAIL_ID = '';
    // HADER FRO API KEY
    static HEADER_API_KEY = 'x-api-key';
    // ENVIROMENT
    // TO RUN LOCALLY USE LOCAL
    // TO RUN IN DEV USE DEV
    // TO RUN IN UAT USE UAT
    // TO RUN IN PROD USE PROD
    static ENVIROMENT = 'PROD';

    // API ACCESS CONSTANTS FOR PROD  
    static readonly API_KEY_URL = 'https://secretsmanager.us-east-1.amazonaws.com/';
    static PROD_API_KEY: string = 'dVcgalovle38SVAl1Etgt17CzMQ3kYx83dgLRK2y';
    static PROD_API_URL = 'https://r2deunttf5.execute-api.us-east-1.amazonaws.com/prod/nios_read_download';

    // API ACCESS CONSTANTS FOR UAT  
    static UAT_API_KEY: string = 'V2G7fILtFf6nXtrNDpXsT4GAiAiy81AJ8xCV2A6a';
    static UAT_API_URL = 'https://b0vfy4zc1f.execute-api.us-east-1.amazonaws.com/v1/nios-read-download-files';


    // API ACCESS CONSTANTS FOR DEV
    static DEV_API_KEY = 'V2G7fILtFf6nXtrNDpXsT4GAiAiy81AJ8xCV2A6a';
    static DEV_API_URL = 'https://b0vfy4zc1f.execute-api.us-east-1.amazonaws.com/v1/nios-read-download-files';

    static getDreamFactoryUrl() {
        switch (this.ENVIROMENT) {
            case 'PROD': return this.OKTA_URL_PROD;
            case 'UAT': return this.OKTA_URL;
            case 'DEV': return this.OKTA_URL;
            case 'LOCAL': return this.OKTA_URL;
        }
    }

    static readonly BUCKET_NAME: string = 'uploaded-files-to-s3';

    // OKta URL
    static readonly OKTA_URL = 'https://7t2btnqupd.execute-api.us-east-1.amazonaws.com/prod/authenticate';
    static readonly OKTA_URL_PROD = 'https://7t2btnqupd.execute-api.us-east-1.amazonaws.com/prod/authenticate';


    // Variables for changing the View
    static quaterStartDate: any;
    static quaterEndDate: any;
    static monthStartDate: any;
    static monthEndDate: any;
    static currentYear: any;
    static currentMonth: any;
    static currentDate: any;
    static currentTime: any;
    static currentTimeStamp: any;
}
